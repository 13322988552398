import {
  CollectionPreferencesProps,
  FlashbarProps,
  NonCancelableCustomEvent,
  PropertyFilterProps,
  TableProps,
} from "@cloudscape-design/components";
import { DropdownStatusProps } from "@cloudscape-design/components/internal/components/dropdown-status";
import React, { useContext, useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { postNewBusinessData } from "../../client/client";
import { IPropertyFilterProps } from "../../components/table/CloudscapeTablePropertyFilter";
import TaCSTableCloudscape from "../../components/table/TaCSTableCloudscape";
import { AuthContext } from "../../context/AuthContext";
import {
  MessageContext,
  MessagesContextType,
} from "../../context/MessagingContext";
import { RepositoryContext } from "../../context/RepositoryContext";
import { 
  updateTableColumnsWithCustomAttributes,
  getTablePreferencesFromLocalStorage,
} from "../../data/config/commonDashboardConfig";
import { BUSINESS_ROUTE_PATHS, ENTITY_TYPE, ITEM_UNIQUE_ID, REQUEST_METHOD } from "../../data/constants/common";
import { DEFAULT_MESSAGE } from "../../data/constants/errorMessages";
import { getEpochTime } from "../../data/helpers/dates";
import {
  csvDownload,
  generateTemplateDownloadCSVName,
} from "../../data/helpers/utils";
import { MediaItem } from "../../data/interfaces/IMediaItems";
import { Attribute } from "../admin/Interface";
import {
  FILTERING_PROPERTIES,
  MEDIA_ITEM_PREFERRED_VISIBLE_COLUMNS,
  MEDIA_ITEM_TABLE_COLUMNS,
  MEDIA_ITEM_VISIBLE_CONTENT_OPTION,
} from "./config";
import { IBusinessGroup } from "../../data/interfaces/IUser";
import { getTokensFromSearchParam } from "../../opensearch/opensearchQueryUtils";

export const MI_BUSINESS_PROP = "mediaItems";

export type Props = {
  getIdToken: () => string;
  selectedBG: IBusinessGroup | null;
  addMessage: (
    message: FlashbarProps.MessageDefinition,
    messageData?: any,
    errorMessage?: string | undefined
  ) => void;
  navigate: NavigateFunction;
  customAttributes: { 
    attributes: Attribute[],
    businessGroupSlug: string;
   } | undefined;
  isLoading: boolean;
  data: any;
  serverError: Error | null;
  refresh: () => void;
  tablePreferences: CollectionPreferencesProps.Preferences<any>;
  setTablePreferences: React.Dispatch<
    React.SetStateAction<CollectionPreferencesProps.Preferences<any>>
  >;
  currentPage: number;
  setCurrentPage: (arg: number) => void;
  searchFilterProps: IPropertyFilterProps;
  sortingColumn?: TableProps.SortingColumn<any>;
  sortingDescending?: boolean;
  onSortingChange?: (arg: any) => void;
  tableColumns: TableProps.ColumnDefinition<MediaItem>[];
  visibleContent: any;
};
export const MediaItems: React.FC<Props> = ({
  getIdToken,
  addMessage,
  navigate,
  selectedBG,
  customAttributes,
  isLoading,
  data,
  serverError,
  refresh,
  tablePreferences,
  setTablePreferences,
  currentPage,
  setCurrentPage,
  searchFilterProps,
  sortingColumn,
  sortingDescending,
  onSortingChange,
  tableColumns,
  visibleContent,
}) => {
  const [selectedItems, setSelectedItems] = useState<MediaItem[]>([]);
  const [loadingTableData, setLoadingTableData] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any>();
  const [, setPosting] = useState<boolean>(false);

  /**
   * Handles the inline update of a media item.
   *
   * @param currentMediaItem {MediaItem} - The current media item being updated.
   * @param col {TableProps.ColumnDefinition<MediaItem>} - The column definition for the updated field.
   * @param newValue {unknown} - The new value for the updated field.
   * @returns {Promise<void>} - A promise that resolves when the update is complete.
   */
  const handleInlineUpdate = async (
    currentMediaItem: MediaItem,
    col: TableProps.ColumnDefinition<MediaItem>,
    newValue: unknown
  ) => {
    // Parse the date value if the updated field is a start or end date
    if (col.id === "startDate" || col.id === "endDate") {
      newValue = getEpochTime(String(newValue));
    }

    await postNewBusinessData({
      newBusinessData: { [col.id as string]: newValue },
      method: REQUEST_METHOD.PATCH,
      getIdToken,
      urlParam: `${MI_BUSINESS_PROP.toLowerCase()}/${
        currentMediaItem.businessUnitSlug
      }/${currentMediaItem.id}`,
      navigate,
      navigateTo: BUSINESS_ROUTE_PATHS.MEDIA_ITEMS,
      successMessage: "Media Item updated successfully",
      errorMessage: "Error updating Media Item",
      addMessage,
      setPosting,
    });
    refresh();
  };

  useEffect(() => {
    setTableData(data);
    setLoadingTableData(false);
  }, [isLoading, currentPage, tablePreferences]);

  const bulkDownloadMediaItems = () => {
    const csvFileName = generateTemplateDownloadCSVName(
      ENTITY_TYPE.MEDIA_ITEMS,
      selectedBG?.businessGroupName || MI_BUSINESS_PROP.toLowerCase(),
      true
    );

    csvDownload(
      selectedItems,
      customAttributes,
      csvFileName,
      ENTITY_TYPE.MEDIA_ITEMS
    );
  };

  const handleMediaItemActions = async () => {
    const buSlugListToAchive = selectedItems.map(
      (mediaItem) => mediaItem.businessUnitSlug
    );
    const mediaItemIdList = selectedItems.map((mediaItem) => mediaItem.id);

    await postNewBusinessData({
      newBusinessData: { mediaItemIdList },
      method: REQUEST_METHOD.POST,
      getIdToken,
      urlParam: `archive/${MI_BUSINESS_PROP.toLowerCase()}?${encodeURI(
        buSlugListToAchive.map((bu) => `bu=${bu}`).join("&")
      )}`,
      navigate,
      navigateTo: BUSINESS_ROUTE_PATHS.MEDIA_ITEMS,
      successMessage: "Media Items successfully achived",
      errorMessage: "Error achiving Media Items, please try again",
      addMessage,
      setPosting,
    });
  };

  return (
    <TaCSTableCloudscape
      tableHeading={`Media Items in ${selectedBG?.businessGroupName}`}
      filteringPlaceholder="Search for Media Items"
      businessProperty={MI_BUSINESS_PROP}
      primaryActionLabel="Bulk download Media Items"
      isLoading={loadingTableData}
      data={tableData}
      serverError={serverError}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      tablePreferences={tablePreferences}
      setTablePreferences={setTablePreferences}
      columnDefinitions={tableColumns}
      visibleContentPreferenceOptions={visibleContent}
      items={tableData?.items ?? tableData?.mediaItems}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      handleTablePrimaryAction={bulkDownloadMediaItems}
      handleTableActions={handleMediaItemActions}
      refetch={refresh}
      handleInlineUpdate={handleInlineUpdate}
      itemId={ITEM_UNIQUE_ID.MEDIA_ITEM}
      searchFilterProps={searchFilterProps}
      sortingColumn={sortingColumn}
      sortingDescending={sortingDescending}
      onSortingChange={onSortingChange}
      idToken={getIdToken}
      customAttributes={customAttributes}
      selectedBG={selectedBG}
    />
  );
};

export const MediaItemsDashboard = () => {
  const { getIdToken, selectedBG, customMediaItemAttributes } = useContext(AuthContext);
  const { mediaItemRepo } = useContext(RepositoryContext);
  const { addMessage } = useContext(MessageContext) as MessagesContextType;
  const navigate = useNavigate();
  const { search } = useLocation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tablePreferences, setTablePreferences] =
    useState<CollectionPreferencesProps.Preferences>(() => {
      return getTablePreferencesFromLocalStorage(
        MI_BUSINESS_PROP,
        MEDIA_ITEM_PREFERRED_VISIBLE_COLUMNS
      );
    });
  const [tableColumns, setTableColumns] = useState(MEDIA_ITEM_TABLE_COLUMNS);
  const [visibleContent, setVisibleContent] = useState(MEDIA_ITEM_VISIBLE_CONTENT_OPTION);
  const [filteringProperties, setFilteringProperties] = useState(FILTERING_PROPERTIES);
  const [searchQuery, setSearchQuery] = useState<PropertyFilterProps.Query>({
    tokens: search ? getTokensFromSearchParam(search) : [],
    operation: "and",
  });
  const [filteringStatusType, setFilteringStatusType] =
    useState<DropdownStatusProps.StatusType>("finished");
  const [filteringOptions, setFilteringOptions] = useState<
    PropertyFilterProps.FilteringOption[]
  >([]);
  const [sortingDescending, setSortingDescending] = useState<
    boolean | undefined
  >(true);
  const [sortingColumn, setSortingColumn] =
    useState<TableProps.SortingColumn<any>>();

  const handleSearchChange = (query: PropertyFilterProps.Query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleSearchOnLoadItems = async (
    event: NonCancelableCustomEvent<PropertyFilterProps.LoadItemsDetail>
  ) => {
    const { filteringProperty } = event.detail;

    if (!filteringProperty) return;

    setFilteringStatusType("loading");
    setFilteringOptions([]);

    await fetchSuggestions(event.detail);
  };

  const fetchSuggestions = async (
    detail: PropertyFilterProps.LoadItemsDetail
  ) => {
    if (detail.filteringProperty) {
      try {
        const extractedList = await mediaItemRepo.fetchMediaItemsSuggestions(
          detail
        );
        setFilteringStatusType("finished");

        if (extractedList) setFilteringOptions(extractedList);
      } catch (e: any) {
        addMessage(
          {
            header: "Failed to load resources",
            type: "error",
            content: DEFAULT_MESSAGE.message,
            dismissible: true,
          },
          e.message
        );
      }
    }
  };

  const { isLoading, data, serverError, refresh } = mediaItemRepo.searchMediaItems(
    searchQuery,
    tablePreferences.pageSize as number,
    currentPage,
    sortingColumn?.sortingField,
    sortingDescending
  );

  const handleSortingChange = (detail: TableProps.SortingState<any>) => {
    setSortingColumn(detail.sortingColumn);
    setSortingDescending(detail.isDescending);
    refresh();
  };

  useEffect(() => {
    const columnsUpdate: TableProps.ColumnDefinition<MediaItem>[] = Object.assign([], MEDIA_ITEM_TABLE_COLUMNS);
    const visiblePreferencesUpdate: any = Object.assign([], MEDIA_ITEM_VISIBLE_CONTENT_OPTION);
    const filteringPropertiesUpdate = Object.assign([], FILTERING_PROPERTIES);

    updateTableColumnsWithCustomAttributes(
      customMediaItemAttributes,
      columnsUpdate,
      visiblePreferencesUpdate,
      filteringPropertiesUpdate,
      setTableColumns,
      setVisibleContent,
      setFilteringProperties
    );

  }, [customMediaItemAttributes]);

  return (
    <MediaItems
      tableColumns={tableColumns}
      visibleContent={visibleContent}
      getIdToken={getIdToken}
      navigate={navigate}
      addMessage={addMessage}
      selectedBG={selectedBG}
      customAttributes={customMediaItemAttributes}
      isLoading={isLoading}
      data={data}
      serverError={serverError}
      refresh={refresh}
      tablePreferences={tablePreferences}
      setTablePreferences={setTablePreferences}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      searchFilterProps={{
        filteringProperties,
        searchQuery: searchQuery,
        handleSearchChange: handleSearchChange,
        filteringStatusType: filteringStatusType,
        filteringOptions: filteringOptions,
        onLoadItems: handleSearchOnLoadItems,
        items: { items: [], page: data.page },
      }}
      sortingColumn={sortingColumn}
      sortingDescending={sortingDescending}
      onSortingChange={({ detail }) => {
        handleSortingChange(detail);
      }}
    />
  );
};
