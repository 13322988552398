import { IBusinessGroup, IBusinessUnit } from "../data/interfaces/IUser";
import { PropertyFilterProps } from "@cloudscape-design/components";
import { buildBasicSearchQuery, buildSuggestionsQuery } from "../opensearch/opensearchQueryUtils";
import IMediaItemRepository from "../interfaces/IMediaItemRepository";
import { ReturnType, useFetchBusinessData } from "../client/client";
import { MI_BUSINESS_PROP } from "../views/media-items/MediaItemsDashboard";
import { ENTITY_TYPE, REQUEST_METHOD } from "../data/constants/common";
import { useFetchOpensearchData } from "../client/opensearchClient";
import { getEnvironmentVariables } from "../data/constants/environment";

function createMediaItemRepository(getToken: () => string, selectedBG: IBusinessGroup | null, customMediaItemAttributes: any = []): IMediaItemRepository {
  return {
    fetchMediaItems(pageSize: number, page: number): ReturnType {
      const buSlugList = Object.keys(selectedBG?.businessUnits as IBusinessUnit);

      return useFetchBusinessData({
        urlPath: `/${MI_BUSINESS_PROP.toLowerCase()}/?${encodeURI(buSlugList.map(
          bu => `bu=${bu}`
        ).join("&"))}&currPage=${page}&pageSize=${pageSize}`,
        method: REQUEST_METHOD.GET,
        getTokenFn: getToken,
      });
    },

    searchMediaItems(searchParams: PropertyFilterProps.Query, pageSize: number, page: number, sortingField?: string, sortingDescending?: boolean): ReturnType {

      const searchQuery = buildBasicSearchQuery(searchParams, pageSize, page, ENTITY_TYPE.MEDIA_ITEMS, selectedBG?.businessGroupSlug, sortingField, sortingDescending, customMediaItemAttributes);
      const body = { index: "mediaitems", query: searchQuery };

      return useFetchOpensearchData({
        body: JSON.stringify(body), getTokenFn: getToken, currentPage: page, pageSize: pageSize
      });
    },

    async fetchMediaItemsSuggestions(itemDetail: PropertyFilterProps.LoadItemsDetail): Promise<PropertyFilterProps.FilteringOption[]> {
      if (itemDetail.filteringProperty?.key?.toLocaleLowerCase()?.includes("date")) {
        return [];
      }
      const token = await getToken();
      const url = getEnvironmentVariables().SEARCH_SERVICE_ENDPOINT;
      const query = buildSuggestionsQuery(itemDetail, selectedBG?.businessGroupSlug);

      const body = { index: "mediaitems", query: query };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) throw new Error("Failed to get suggestions");

      const data = await response.json();

      // Extract suggestions and ensure each field name is extracted once per hit
      const suggestions = data.search_results.hits.hits.map((hit: any) => {
        const keys = Object.keys(hit._source);
        return keys.length > 0 && itemDetail?.filteringProperty?.key ? hit._source[itemDetail.filteringProperty.key] : undefined;
      }).filter(Boolean); // Remove undefined entries if any

      // Map the unique suggestions to the desired format
      const extractedList = suggestions.map((option: any) => ({
        propertyKey: itemDetail.filteringProperty?.key,
        value: option,
      } as PropertyFilterProps.FilteringOption));

      return extractedList;
    },

  };
}

export { createMediaItemRepository };
