import ICampaignRepository from "../interfaces/ICampaignRepository";
import { IPage, IPaginated } from "../data/interfaces/ICampaign";
import { IBusinessGroup, IBusinessUnit } from "../data/interfaces/IUser";
import { getEnvironmentVariables } from "../data/constants/environment";
import { buildBasicSearchQuery, buildSuggestionsQuery, transformOpensearchResponse } from "../opensearch/opensearchQueryUtils";
import { PropertyFilterProps } from "@cloudscape-design/components";
import { ENTITY_TYPE } from "../data/constants/common";

function createCampaignRepository(getToken: () => Promise<string>, selectedBG: IBusinessGroup | null, customCampaignAttributes: any = []): ICampaignRepository {
  return {
    async fetchCampaigns(queryParams: string, pageSize: number, page: number): Promise<IPaginated> {
      const token = await getToken();
      const bus = Object.keys(selectedBG?.businessUnits as IBusinessUnit);

      const url = `${getEnvironmentVariables().API_ENDPOINT}/campaigns${"?" + encodeURI(bus.map((bu) => `bu=${bu}`).join("&")) + "&"}` +
        `currPage=${page}&pageSize=${pageSize
        }${queryParams}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
      });


      if (!response.ok) throw new Error("Failed to fetch campaigns");
      return response.json();
    },

    async searchCampaigns(searchParams: PropertyFilterProps.Query, pageSize: number, page: number, sortingField?: string, sortingDescending?: boolean): Promise<IPaginated> {
      const token = await getToken();
      const url = getEnvironmentVariables().SEARCH_SERVICE_ENDPOINT;

      const searchQuery = buildBasicSearchQuery(searchParams, pageSize, page, ENTITY_TYPE.CAMPAIGNS, selectedBG?.businessGroupSlug, sortingField, sortingDescending, customCampaignAttributes);
      const body = { index: "campaigns", query: searchQuery };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) return { items: [], page: {} as IPage };

      const data = await response.json();
      const transformedData = transformOpensearchResponse(data, page, pageSize, true);

      return transformedData;
    },

    async fetchCampaignSuggestions(itemDetail: PropertyFilterProps.LoadItemsDetail): Promise<PropertyFilterProps.FilteringOption[]> {
      if (itemDetail.filteringProperty?.key?.toLocaleLowerCase()?.includes("date")) {
        return [];
      }

      const token = await getToken();
      const url = getEnvironmentVariables().SEARCH_SERVICE_ENDPOINT;
      const query = buildSuggestionsQuery(itemDetail, selectedBG?.businessGroupSlug);

      const body = { index: "campaigns", query: query };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) throw new Error("Failed to get suggestions");

      const data = await response.json();

      // Extract suggestions and ensure each field name is extracted once per hit
      const suggestions = data.search_results.hits.hits.map((hit: any) => {
        const keys = Object.keys(hit._source);
        return keys.length > 0 && itemDetail?.filteringProperty?.key ? hit._source[itemDetail.filteringProperty.key] : undefined;
      }).filter(Boolean); // Remove undefined entries if any

      // Map the unique suggestions to the desired format
      const extractedList = suggestions.map((option: any) => ({
        propertyKey: itemDetail.filteringProperty?.key,
        value: option,
      } as PropertyFilterProps.FilteringOption));

      return extractedList;
    },

  };
}

export { createCampaignRepository };
